import React, { useState, useEffect, useContext } from "react";
import { rxOpdApi } from "../../utils/api/api";
import { RX_OPD_ENDPOINTS } from "../../utils/api/apiEndPoints";
import ModeContext from "../../context/mode-context";
import HospitalsContext from "../../context/hospitals-context";
import { bioMarksBloodPressure } from "./ClinicalUtils";
import Button from "../../Tools/Button";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import Slider from "@mui/material/Slider";
import BookletModal from "../booklet/BookletModal";
import Toast from "../ui/Toast";
const ClinicalFindings = ({ appointmentId }) => {
  const [bioMarkers, setBioMarkers] = useState([]);
  const [capture, setCaptured] = useState({});
  const [savedMarkers, setSavedMarkers] = useState({});
  const { mode } = useContext(ModeContext);
  const { currentHospital } = useContext(HospitalsContext);
  const [clinicalLoader, setClinicalLoader] = useState(false);
  const [pdfDatas, setPdfDatas] = useState(null);
  const [isModalOpens, setIsModalOpens] = useState(false);
  const userKeys = localStorage.getItem("usr_keys");
  const userModeKey = JSON.parse(userKeys)[mode];
  const key = userModeKey[`${mode}_key`];
  const secret = userModeKey[`${mode}_secret`];
    const [toastType, setToastType] = useState("");
    const [showToast, setShowToast] = useState(false);
    const [toastMessage, setToastMessage] = useState(null);
  useEffect(() => {
    const ClinicalFindingsCall = async () => {
      setClinicalLoader(true);
      try {
        const userKeys = localStorage.getItem("usr_keys");
        const userModeKey = JSON.parse(userKeys)[mode];

        const key = userModeKey[`${mode}_key`];
        const secret = userModeKey[`${mode}_secret`];

        rxOpdApi.setAuthHeaders(key, secret);
        const res = await rxOpdApi.get(
          `${RX_OPD_ENDPOINTS.HOSPITAL.VITALS.FETCH_BIOMARKERS}/${mode}/${currentHospital.hos_id}`
        );

        const clinicalData = res?.data?.records;
        const systolicIndex = clinicalData?.findIndex(
          (obj) => obj?.display_name === bioMarksBloodPressure?.systolic
        );
        const diastolicIndex = clinicalData?.findIndex(
          (obj) => obj?.display_name === bioMarksBloodPressure?.diastolic
        );

        let processedClinicalData = clinicalData?.filter(
          (items) => 
            items?.display_name !== bioMarksBloodPressure?.systolic &&
            items?.display_name !== bioMarksBloodPressure?.diastolic
        );

        if (systolicIndex !== -1 && diastolicIndex !== -1) {
          processedClinicalData.push({
            display_name: bioMarksBloodPressure.default,
            systolic: clinicalData[systolicIndex],
            diastolic: clinicalData[diastolicIndex],
            checked: false
          });
        }

        setBioMarkers(processedClinicalData);
      } catch (err) {
        console.error("Error fetching clinical data:", err);
      } finally {
        setClinicalLoader(false);
      }
    };
    ClinicalFindingsCall();
  }, [mode, currentHospital]);

  const handleSliderChange = (item, newValue) => {
    setCaptured((prev) => ({
      ...prev,
      [item.bio_marker]: {
        ...prev[item.bio_marker],
        ...item,
        sliderValue: newValue,
      },
    }));
  };

  const handleOnClick = async (item, index) => {
    try {
      const updatedBioMarkers = bioMarkers.map((marker, idx) => ({
        ...marker,
        checked: idx === index
      }));

      if (item.display_name.toLowerCase().includes("blood pressure")) {
        const basePath = `${RX_OPD_ENDPOINTS.HOSPITAL.VITALS.FETCH_BIOMARKERS_DATA}/${mode}/${currentHospital.hos_id}/${appointmentId}`;

        const [systolicRes, diastolicRes] = await Promise.all([
          rxOpdApi.get(`${basePath}/blood_pressure_syst`),
          rxOpdApi.get(`${basePath}/blood_pressure_dias`)
        ]);

        setCaptured({
          blood_pressure_syst: {
            bio_marker: 'blood_pressure_syst',
            display_name: 'Systolic Blood Pressure',
            sliderValue: systolicRes.data?.record?.latest_measure || 120,
            lower_limit: 40,
            upper_limit: 300,
            measuring_unit: 'mmHg',
            step: 1
          },
          blood_pressure_dias: {
            bio_marker: 'blood_pressure_dias',
            display_name: 'Diastolic Blood Pressure',
            sliderValue: diastolicRes.data?.record?.latest_measure || 80,
            lower_limit: 0,
            upper_limit: 200,
            measuring_unit: 'mmHg',
            step: 1
          }
        });
      } else {
        const res = await rxOpdApi.get(
          `${RX_OPD_ENDPOINTS.HOSPITAL.VITALS.FETCH_BIOMARKERS_DATA}/${mode}/${currentHospital.hos_id}/${appointmentId}/${item.bio_marker}`
        );

        const defaultValue =
          res.data?.record?.latest_measure ||
          Number(item.median) ||
          (Number(item.lower_limit) + Number(item.upper_limit)) / 2;

        setCaptured({
          [item.bio_marker]: {
            ...item,
            sliderValue: defaultValue,
          }
        });
      }

      setBioMarkers(updatedBioMarkers);
    } catch (err) {
      console.error("Error fetching biomarker data:", err);
    }
  };

  const handleSave = async (item) => {
    setClinicalLoader(true);
    const userKeys = localStorage.getItem("usr_keys");
    const userModeKey = JSON.parse(userKeys)[mode];
    const key = userModeKey[`${mode}_key`];
    const secret = userModeKey[`${mode}_secret`];
    try {
      rxOpdApi.setAuthHeaders(key, secret);

      if (item.display_name.toLowerCase().includes("blood pressure")) {
        // Save both systolic and diastolic values
        await Promise.all([
          rxOpdApi.put(
            `${RX_OPD_ENDPOINTS.HOSPITAL.VITALS.FETCH_BIOMARKERS_DATA}/${mode}/${currentHospital.hos_id}/${appointmentId}/blood_pressure_syst`,
            {
              measure: String(capture.blood_pressure_syst.sliderValue),
            }
          ),
          rxOpdApi.put(
            `${RX_OPD_ENDPOINTS.HOSPITAL.VITALS.FETCH_BIOMARKERS_DATA}/${mode}/${currentHospital.hos_id}/${appointmentId}/blood_pressure_dias`,
            {
              measure: String(capture.blood_pressure_dias.sliderValue),
            }
          )
        ]);

        setSavedMarkers(prev => ({
          ...prev,
          blood_pressure: true
        }));
      } else {
        const valueToSave = capture[item.bio_marker]?.sliderValue;
        await rxOpdApi.put(
          `${RX_OPD_ENDPOINTS.HOSPITAL.VITALS.FETCH_BIOMARKERS_DATA}/${mode}/${currentHospital.hos_id}/${appointmentId}/${item.bio_marker}`,
          {
            measure: String(valueToSave),
          }
        );
        
        setSavedMarkers(prev => ({
          ...prev,
          [item.bio_marker]: true
        }));
      }

      const index = bioMarkers.findIndex(marker => 
        marker.bio_marker === item.bio_marker || 
        marker.display_name === item.display_name
      );

      if (index !== -1) {
        await handleOnClick(bioMarkers[index], index);
      }

    } catch (err) {
      console.error(`Error saving ${item.display_name}:`, err);
    } finally {
      setClinicalLoader(false);
    }
  };

  const handleview = async() =>{
    setClinicalLoader(true);
    const userKeys = localStorage.getItem("usr_keys");
    const userModeKey = JSON.parse(userKeys)[mode];
    const key = userModeKey[`${mode}_key`];
    const secret = userModeKey[`${mode}_secret`];
    try{
      rxOpdApi.setAuthHeaders(key, secret);
      const res = await rxOpdApi.put(`${RX_OPD_ENDPOINTS.HOSPITAL.VITALS.GENERATE_VITALS}/${mode}/${currentHospital.hos_id}/${appointmentId}`);
      if(res){
        setClinicalLoader(false);
        handlepost();
      }
    }catch(err){}
  }

    const handlepost = async () => {
      setClinicalLoader(true);
  
      const apiUrl =
        process.env.REACT_APP_RX_OPD +
        RX_OPD_ENDPOINTS.HOSPITAL.VITALS.VIEW_VITALS +
        "/" +
        mode +
        "/" +
        currentHospital.hos_id +
        "/" +
        appointmentId;
  
      // Fetch the PDF data
      fetch(apiUrl, {
        method: "get",
        headers: {
          "Content-Type": "application/pdf",
          Authorization: "Basic " + btoa(key + ":" + secret),
        },
      })
        .then((response) => response.blob())
        .then((blob) => {
          // Convert the PDF blob to a URL and set it in state
          const blobUrl = URL.createObjectURL(blob);
          setPdfDatas(blobUrl);
  
          // Open the modal to display the PDF
          setIsModalOpens(true);
          setClinicalLoader(false);
        });
    };

    const handleCloseModals = () => {
      setIsModalOpens(false);
      setClinicalLoader(false);

    };

    return (
      <>
        <div className="clinical-findings">
          {clinicalLoader && (
            <Backdrop
              sx={{
                color: "#fff",
                opacity: "0.6",
                zIndex: (theme) => theme.zIndex.drawer + 1,
              }}
              open={clinicalLoader}
              onClick={() => {}}
            >
              <CircularProgress size="20px" color="inherit" />
            </Backdrop>
          )}
  
          <div className="clinical-findings-container">
            <div className="clinical-findings-heading mt-2 mb-3">
              To capture clinical findings click the below bubbles (Bio-Markers)
            </div>
            <div className="clinical-findings-bubbles">
              {bioMarkers?.map((items, index) => {
                const isSaved = savedMarkers[
                  items.bio_marker || 
                  items.display_name.toLowerCase().replace(/\s+/g, '_')
                ];
  
                return (
                  <Button
                    key={index}
                    text={items?.display_name}
                    handleClick={() => !isSaved && handleOnClick(items, index)}
                    containerClass={`${
                      isSaved 
                        ? "static-disable" 
                        : items?.checked 
                        ? "" 
                        : "clinical-findings-bubbles-disable"
                    } clinical-findings-bubbles`}
                  />
                );
              })}
            </div>
            <div
              className="clinicalFindings_slider mt-4"
              style={{ overflowY: "auto"}}
            >
              {bioMarkers?.map((items, index) => (
                items.checked && (
                  <React.Fragment key={index}>
                    {items.display_name.toLowerCase().includes("blood pressure") ? (
                      <div className="mt-2 mb-2">
                        {["blood_pressure_syst", "blood_pressure_dias"].map((bpType) => {
                          const bpItem = capture[bpType];
                          return bpItem ? (
                            <div key={bpType}>
                              <div className="clinical-findings-slider-heading">
                                {`${bpItem.display_name} (${bpItem.measuring_unit})`}
                              </div>
                              <hr />
                              <div className="duration-slider">
                                <Slider
                                  value={bpItem.sliderValue || 0}
                                  onChange={(e, newValue) => 
                                    handleSliderChange(bpItem, newValue)
                                  }
                                  min={bpItem.lower_limit}
                                  max={bpItem.upper_limit}
                                  step={bpItem.step}
                                  valueLabelDisplay="auto"
                                />
                                <div className="duration-slider-manual">
                                  <p>
                                    Current Measure for {bpItem.display_name} (
                                    {bpItem.measuring_unit})
                                  </p>
                                  <input
                                    type="number"
                                    value={bpItem.sliderValue || 0}
                                    min={bpItem.lower_limit}
                                    max={bpItem.upper_limit}
                                    onChange={(e) => {
                                      const newValue = Number(e.target.value);
                                      handleSliderChange(bpItem, newValue);
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                          ) : null;
                        })}
                        <Button
                          text="Save"
                          handleClick={() => handleSave(items)}
                          containerClass="mt-2 mr-auto ml-auto duration-slider-submit"
                        />
                      </div>
                    ) : (
                      <div key={index} className="mt-2 mb-2">
                        <div className="clinical-findings-slider-heading">
                          {`${items?.display_name} (${items?.measuring_unit})`}
                        </div>
                        <hr />
                        <div className="duration-slider">
                          <Slider
                            value={
                              capture[items.bio_marker]?.sliderValue ||
                              Number(items.median) ||
                              0
                            }
                            onChange={(e, newValue) =>
                              handleSliderChange(items, newValue)
                            }
                            min={Number(items.lower_limit)}
                            max={Number(items.upper_limit)}
                            step={Number(items.step)}
                            valueLabelDisplay="auto"
                          />
                          <div className="duration-slider-manual">
                            <p>
                              Current Measure for {items?.display_name} (
                              {items?.measuring_unit})
                            </p>
                            <input
                              type="number"
                              value={
                                capture[items.bio_marker]?.sliderValue ||
                                Number(items.median) ||
                                0
                              }
                              min={Number(items.lower_limit)}
                              max={Number(items.upper_limit)}
                              onChange={(e) => {
                                const newValue = Number(e.target.value);
                                handleSliderChange(items, newValue);
                              }}
                            />
                            <Button
                              text="Save"
                              handleClick={() => handleSave(items)}
                              containerClass="mt-2 mr-auto ml-auto duration-slider-submit"
                            />
                          </div>
                        </div>
                      </div>
                    )}
                  </React.Fragment>
                )
              ))}
            </div>
  
            <Button
              text="View/Generate"
              containerClass="mt-2 mr-auto ml-auto duration-slider-submit"
              handleClick={handleview}
            />
            <BookletModal
              isOpen={isModalOpens}
              onClose={handleCloseModals}
              hosid={currentHospital.hos_id}
              pdfData={pdfDatas}
            />
          </div>
        </div>
        {showToast && (
          <Toast
            type={toastType}
            show={showToast}
            handleToastClose={setShowToast}
          >
            {toastMessage}
          </Toast>
        )}
      </>
    );
  };

export default ClinicalFindings;