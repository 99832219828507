import { useContext, useEffect, useState } from "react";
import Select from "react-select";
import { DayPilotCalendar } from "@daypilot/daypilot-lite-react";
import Col from "react-bootstrap/Col";
import Spinner from "react-bootstrap/Spinner";

import ModeContext from "../../context/mode-context";
import HospitalsContext from "../../context/hospitals-context";
import SelectHospital from "../hospitals/SelectHospital";
import AppointmentsForm from "./AppointmentsForm";
import Calendar from "./Calendar";
import Button from "../ui/Button";
import Toast from "../ui/Toast";
import { rxOpdApi } from "../../utils/api/api";
import { RX_OPD_ENDPOINTS } from "../../utils/api/apiEndPoints";
import { styled } from "@mui/material/styles";
import { Switch } from "@mui/material";
import Appointmentdate from "./Appointmentdate";
import DateRange from "../date-range/DateRange";
const allevents = [
  {
    id: 1,
    text: "Event 1",
    start: new Date(2022, 9, 31),
    end: new Date(2022, 9, 31),
  },
  {
    id: 2,
    text: "Event 2",
    start: new Date(2022, 9, 31),
    end: new Date(2022, 9, 31),
  },
  {
    id: 3,
    text: "Event 3",
    start: new Date(2022, 9, 31),
    end: new Date(2022, 9, 31),
  },
];

const CustomSwitch = styled(Switch)(({ theme }) => ({
  width: 120,
  height: 34,
  padding: 0,
  borderRadius: 17,
  cursor: "pointer",
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    cursor: "pointer",
    "&.Mui-checked": {
      transform: "translateX(90px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: "rgb(77, 154, 166)",
        opacity: 1,
        border: 0,
        cursor: "pointer",
        "&:before": {
          opacity: 0,
        },
        "&:after": {
          opacity: 1,
        },
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "rgb(77, 154, 166)",
      border: "6px solid #fff",
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 30,
    height: 30,
    color: "#fff",
    cursor: "pointer",
    marginRight: "5rem",
  },
  "& .MuiSwitch-track": {
    borderRadius: 17,
    backgroundColor: "rgb(77, 154, 166)",
    opacity: 1,
    cursor: "pointer",
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
    "&:before, &:after": {
      content: '""',
      position: "absolute",
      top: "50%",
      transform: "translateY(-50%)",
      width: "70px",
      color: "#fff",
      textAlign: "center",
      fontSize: "14px",
      fontWeight: 500,
      transition: "opacity 300ms ease",
      cursor: "pointer",
      userSelect: "none", // Prevent text selection
    },
    "&:before": {
      content: '"List"',
      left: 30,
      opacity: 1,
      pointerEvents: "none", // Make text non-interactive
    },
    "&:after": {
      content: '"Calendar"',
      right: 30,
      opacity: 0,
      pointerEvents: "none", // Make text non-interactive
    },
  },
  // Add styles for the wrapper element to make entire area clickable
  "& .MuiButtonBase-root": {
    width: "90%",
    // height: "100%",
    cursor: "pointer",
  },
}));

function AppointmentsTab2(props) {
  const { mode } = useContext(ModeContext);
  const { currentHospital, isLoading: areHospitalsLoading } =
    useContext(HospitalsContext);

  const [allDocs, setAllDocs] = useState(null);
  const [selectedDocId, setSelectedDocId] = useState(null);
  const [loadingDocs, setLoadingDocs] = useState(true);
  const [showToast, setShowToast] = useState(false);
  const [toastType, setToastType] = useState("");
  const [toastMessage, setToastMessage] = useState("");
  const [showAppointmentsForm, setShowAppointmentsForm] = useState(false);
  const [isCalendarMode, setIsCalendarMode] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState("date"); // Add this state
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(
    new Date(new Date().setDate(new Date().getDate() + 1))
  );
  useEffect(() => {
    const fetchDocs = async () => {
      setLoadingDocs(true);

      try {
        const userKeys = localStorage.getItem("usr_keys");
        const userModeKey = JSON.parse(userKeys)[mode];

        const key = userModeKey[`${mode}_key`];
        const secret = userModeKey[`${mode}_secret`];

        rxOpdApi.setAuthHeaders(key, secret);
        const allDocsRes = await rxOpdApi.get(
          RX_OPD_ENDPOINTS.HOSPITAL.APPOINTMENT.LIST_ALL_DOCTORS +
            "/" +
            currentHospital.hos_id
        );

        if (allDocsRes && allDocsRes.data.records.length > 0) {
          const allDocs = allDocsRes.data.records;
          setAllDocs(allDocs);
          setSelectedDocId(allDocs[0].doc_id);  // This also sets the first doctor as selected
        } else {
          setAllDocs([]);
          setSelectedDocId(null);
        }
      } catch (error) {
        setShowToast(true);
        setToastType("error");
        setToastMessage(error.message);
      } finally {
        setLoadingDocs(false);
      }
    };

    currentHospital && Object.keys(currentHospital).length > 0 && fetchDocs();
  }, [mode, currentHospital]);

  return (
    <>
      <div
        style={{
          position: "absolute",
          top: 120,
          right: "40px",
          display: "flex",
          alignItems: "center",
          gap: "8px",
          zIndex: 2,
        }}
      >
        <span className="text-nowrap">View as:</span>
        <div style={{ cursor: "pointer" }}>
          <CustomSwitch
            checked={isCalendarMode}
            onChange={(e) => setIsCalendarMode(e.target.checked)}
          />
        </div>
      </div>

      <div className="px-4 py-4">
        <div className="row align-items-center mb-4">
          {/* Hospital Selection */}
          <div className="col-12 col-md-5 mb-3 mb-md-0">
            <SelectHospital type="appointment" />
          </div>
          {/* Book Button */}
          <div className="col-12 col-md-2">
            <Button
              style={{
                backgroundColor: "rgb(77, 154, 166)",
                border: "none",
                borderRadius: "10px",
              }}
              onClick={() => setShowAppointmentsForm(true)}
            >
              Book
            </Button>
          </div>
          {/* Doctor Selection */}
          <div className="col-12 col-md-5 mb-3 mb-md-0">
            <div className="d-flex align-items-center" style={{gap: "1rem"}}>
              <label className="mb-0 whitespace-nowrap">Select Doctor: </label>
              <Select
                key={allDocs ? allDocs[0]?.doc_id : 'no-docs'}  // Force re-render if docs change
                isLoading={loadingDocs}
                options={allDocs?.map((doc) => ({
                  label: doc.doc_name,
                  value: doc.doc_id,
                }))}
                defaultValue={{
                  label: allDocs ? allDocs[0]?.doc_name : "",
                  value: allDocs ? allDocs[0]?.doc_id : "",
                }}
                onChange={({ value }) => setSelectedDocId(value)}
                styles={{
                  control: (controlStyles) => ({
                    ...controlStyles,
                    border: "2px solid #b3c6e7",
                    borderRadius: "0",
                  }),
                  menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                  container: (base) => ({
                    ...base,
                    flex: 1,
                  }),
                }}
                menuPortalTarget={document.body}
                menuPosition="fixed"
              />
            </div>
          </div>
        </div>

        {/* Rest of the component remains the same */}
        {!isCalendarMode && (
          <div className="row mb-4" style={{marginLeft: ".1rem"}}>
            <div className="col-12">
              <div className="d-flex flex-column flex-md-row align-items-start align-items-md-center gap-2">
                <DateRange
                  startDate={startDate}
                  setStartDate={setStartDate}
                  endDate={endDate}
                  setEndDate={setEndDate}
                  label="Date range:"
                />
              </div>
            </div>
          </div>
        )}

        <div className="row">
          {isCalendarMode ? (
            <Calendar selectedDocId={selectedDocId} />
          ) : (
            <div style={{marginTop: "-0.75rem"}}>
              <Appointmentdate
                selectedDocId={selectedDocId}
                startDate={startDate}
                endDate={endDate}
                setStartDate={setStartDate}
                setEndDate={setEndDate}
              />
            </div>
          )}
        </div>
      </div>

      {showAppointmentsForm && (
        <AppointmentsForm
          show={showAppointmentsForm}
          onHide={() => setShowAppointmentsForm(false)}
        />
      )}

      {showToast && (
        <Toast
          type={toastType}
          show={showToast}
          handleToastClose={setShowToast}
        >
          {toastMessage}
        </Toast>
      )}
    </>
  );
}

export default AppointmentsTab2;
