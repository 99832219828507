import { useState, useEffect, useContext } from "react";
import DateRange from "../date-range/DateRange";
import { Col } from "react-bootstrap";
import ModeContext from "../../context/mode-context";
import HospitalsContext from "../../context/hospitals-context";
import Toast from "../ui/Toast";
import { rxOpdApi } from "../../utils/api/api";
import { RX_OPD_ENDPOINTS } from "../../utils/api/apiEndPoints";
import { styled } from "@mui/material";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { DataGrid } from "@mui/x-data-grid";
import Box from "@mui/material/Box";
import AppointmentModal from "./AppointmentModal";
import SearchIcon from "../../assets/images/static/SearchIcon";
import Button from "../ui/Button";
import CachedIcon from "@mui/icons-material/Cached";

const Appointmentdate = ({
  selectedDocId,
  startDate,
  endDate,
  setStartDate,
  setEndDate,
}) => {
  const { mode } = useContext(ModeContext);
  const { currentHospital } = useContext(HospitalsContext);
  const [appointments, setAppointments] = useState([]);
  const [clinicalLoader, setClinicalLoader] = useState(false);
  const [showAppointmentModal, setShowAppointmentModal] = useState(false);
  const [selectedAppointmentId, setSelectedAppointmentId] = useState(null);
  const [selectedFilter, setSelectedFilter] = useState("name");
  const filterBubbles = [
    { id: "name", label: "Name" },
    { id: "phone", label: "Phone" },
    { id: "case_id", label: "Case#" },
    { id: "token", label: "Token#" },
  ];

  const getYyyyMmDdDate = (date) => {
    return date.toISOString().split("T")[0];
  };
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredAppointments, setFilteredAppointments] = useState([]);
  const [hasMore, setHasMore] = useState(true);

  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 20,
  });

  // State to store data for each page
  const [pageData, setPageData] = useState({});

  const fetchAppointments = async (page = 1) => {
    setClinicalLoader(true);
    try {
      const userKeys = localStorage.getItem("usr_keys");
      const userModeKey = JSON.parse(userKeys)[mode];

      const key = userModeKey[`${mode}_key`];
      const secret = userModeKey[`${mode}_secret`];

      rxOpdApi.setAuthHeaders(key, secret);
      const appointmentsRes = await rxOpdApi.get(
        `${RX_OPD_ENDPOINTS.HOSPITAL.APPOINTMENT.LIST_APPOINTMENT_PAGE}/${
          currentHospital.hos_id
        }/${selectedDocId}/${getYyyyMmDdDate(startDate)}/${getYyyyMmDdDate(
          endDate
        )}?page_number=${page}&page_size=${paginationModel.pageSize}`
      );

      if (appointmentsRes?.data?.records) {
        const formattedAppointments = appointmentsRes.data.records.map(
          (appointment) => ({
            id: appointment?.appointment_id,
            date: appointment?.appointment_date_formatted,
            time: appointment?.appointment_time_formatted,
            case: appointment?.case_id,
            token: appointment?.appointment_token_no || "-",
            "app type": appointment?.app_type,
            name: appointment?.patient_name,
            age: appointment?.patient_age,
            gender: appointment?.patient_gender,
            phone: appointment?.patient_phone || "",
            appointment_id: appointment?.appointment_id,
            "app status": appointment?.app_status,
            appointment_card_status: appointment?.appointment_card_status,
            appointment_card_status_color_code:
              appointment?.appointment_card_status_color_code,
          })
        );

        // Store data for the current page
        setPageData((prevData) => ({
          ...prevData,
          [page]: formattedAppointments,
        }));

        setAppointments(formattedAppointments);
        setFilteredAppointments(formattedAppointments);
      } else {
        setAppointments([]);
        setFilteredAppointments([]);
        setHasMore(false);
      }
    } catch (error) {
      console.error("Error fetching appointments:", error);
      setHasMore(false);
    } finally {
      setClinicalLoader(false);
    }
  };

  useEffect(() => {
    if (selectedDocId) {
      fetchAppointments();
    }
  }, [startDate, endDate, selectedDocId]);

  useEffect(() => {
    if (!showAppointmentModal) {
      fetchAppointments();
    }
  }, [showAppointmentModal]);

  const isValidName = (value) => {
    const nameRegex = /^[A-Za-z\s]*$/;
    return nameRegex.test(value);
  };

  const isValidPhone = (value) => {
    const phoneRegex = /^\d*$/;
    return phoneRegex.test(value);
  };

  const isValidToken = (value) => {
    const tokenRegex = /^[A-Za-z]{2}\d+$/;
    return tokenRegex.test(value);
  };

  const handleSearchInput = (e) => {
    const value = e.target.value;
    setSearchQuery(value);

    if (!value.trim()) {
      setFilteredAppointments(appointments);
      return;
    }

    if (
      (selectedFilter === "name" && !isValidName(value)) ||
      (selectedFilter === "phone" && !isValidPhone(value)) ||
      (selectedFilter === "case_id" && !isValidPhone(value)) ||
      (selectedFilter === "token" && !isValidToken(value))
    ) {
      return;
    }

    const filtered = appointments.filter((appointment) => {
      if (selectedFilter === "name") {
        return appointment.name?.toLowerCase().includes(value.toLowerCase());
      } else if (selectedFilter === "phone") {
        return appointment.phone?.toString().includes(value);
      } else if (selectedFilter === "case_id") {
        return appointment.case?.toString().includes(value);
      } else if (selectedFilter === "token") {
        return appointment.token?.toUpperCase().includes(value.toUpperCase());
      }
      return true;
    });

    setFilteredAppointments(filtered);
  };

  const handleSearchIconClick = () => {
    if (!searchQuery.trim()) {
      setFilteredAppointments(appointments);
      return;
    }

    if (
      (selectedFilter === "name" && !isValidName(searchQuery)) ||
      (selectedFilter === "phone" && !isValidPhone(searchQuery)) ||
      (selectedFilter === "case_id" && !isValidPhone(searchQuery)) ||
      (selectedFilter === "token" && !isValidToken(searchQuery))
    ) {
      return;
    }
  };

  const getErrorMessage = () => {
    if (selectedFilter === "name" && searchQuery && !isValidName(searchQuery)) {
      return "Please enter alphabets only";
    }
    if (
      selectedFilter === "phone" &&
      searchQuery &&
      !isValidPhone(searchQuery)
    ) {
      return "Please enter numbers only";
    }
    if (
      selectedFilter === "token" &&
      searchQuery &&
      !isValidToken(searchQuery)
    ) {
      return "Please enter a valid token";
    }
    if (
      selectedFilter === "case_id" &&
      searchQuery &&
      !isValidPhone(searchQuery)
    ) {
      return "Please enter numbers only";
    }
    return "";
  };

  const getSearchPlaceholder = () => {
    switch (selectedFilter) {
      case "name":
        return "filter by name";
      case "case_id":
        return "filter by case number";
      case "token":
        return "filter by token";
      case "phone":
        return "filter by phone";
      default:
        return "Search";
    }
  };

  const handleRowClick = (params) => {
    setSelectedAppointmentId(params.row.appointment_id);
    setShowAppointmentModal(true);
  };

  const columns = [
    {
      field: "date",
      headerName: "DATE",
      headerAlign: "left",
      width: 100,
      headerClassName: "super-app-theme--header",
      renderCell: (params) => (
        <div style={{ fontSize: "12px" }}>{params.value}</div>
      ),
    },
    {
      field: "time",
      headerName: "TIME",
      width: 100,
      headerClassName: "super-app-theme--header",
      headerAlign: "left",
      renderCell: (params) => (
        <div style={{ fontSize: "12px" }}>{params.value}</div>
      ),
    },
    {
      field: "case",
      headerName: "CASE#",
      width: 100,
      headerClassName: "super-app-theme--header",
      headerAlign: "left",
      renderCell: (params) => (
        <div style={{ fontSize: "12px" }}>{params.value}</div>
      ),
    },
    {
      field: "token",
      headerName: "TOKEN#",
      headerClassName: "super-app-theme--header",
      width: 100,
      headerAlign: "left",
      renderCell: (params) => (
        <div style={{ fontSize: "12px" }}>{params.value}</div>
      ),
    },
    {
      field: "app type",
      headerName: "TYPE",
      width: 150,
      headerClassName: "super-app-theme--header",
      headerAlign: "left",
      renderCell: (params) => (
        <div style={{ fontSize: "12px" }}>{params.value}</div>
      ),
    },
    {
      field: "name",
      headerName: "NAME",
      headerAlign: "left",
      width: 150,
      headerClassName: "super-app-theme--header",
      renderCell: (params) => (
        <div style={{ fontSize: "12px" }}>{params.value}</div>
      ),
    },
    {
      field: "age",
      headerName: "AGE",
      width: 100,
      headerClassName: "super-app-theme--header",
      headerAlign: "left",
      renderCell: (params) => (
        <div style={{ fontSize: "12px" }}>{params.value}</div>
      ),
    },
    {
      field: "gender",
      headerName: "GENDER",
      width: 100,
      headerClassName: "super-app-theme--header",
      headerAlign: "left",
      renderCell: (params) => (
        <div style={{ fontSize: "12px" }}>{params.value}</div>
      ),
    },
    {
      field: "app status",
      headerName: "STATUS",
      width: 200,
      headerClassName: "super-app-theme--header",
      headerAlign: "left",
      renderCell: (params) => {
        const appointment = appointments.find(
          (app) => app.id === params.row.id
        );
        return (
          <div
            style={{
              fontSize: "12px",
              backgroundColor:
                appointment?.appointment_card_status_color_code ||
                "transparent",
              color: appointment?.appointment_card_status_color_code
                ? "#fff"
                : "#000",
              padding: "4px 8px",
              borderRadius: "4px",
              display: "inline-block",
            }}
          >
            {appointment?.appointment_card_status || params.value}
          </div>
        );
      },
    },
  ];

  const handlePaginationChange = (model) => {
    setPaginationModel(model);

    if (model.page > paginationModel.page) {
      fetchAppointments(model.page + 1);
    } else {
      // Use stored data for previous pages
      setAppointments(pageData[model.page + 1] || []);
      setFilteredAppointments(pageData[model.page + 1] || []);
    }
  };

  useEffect(() => {
    setHasMore(filteredAppointments.length > 0);
  }, [filteredAppointments]);

  return (
    <>
      {clinicalLoader && (
        <Backdrop
          sx={{
            color: "#fff",
            opacity: "0.6",
            zIndex: (theme) => theme.zIndex.drawer + 1,
          }}
          open={clinicalLoader}
          onClick={() => {}}
        >
          <CircularProgress size="20px" color="inherit" />
        </Backdrop>
      )}
      <div className="">
        <div
          className="flex items-center gap-4"
          style={{
            display: "flex",
            margin: "1rem",
            justifyContent: "space-between",
          }}
        >
          <div style={{ display: "flex", alignItems: "center", gap: "1rem" }}>
            <p
              className="newappointment__container__calender__text"
              style={{ marginBottom: "0" }}
            >
              Filter by:
            </p>
            <div
              className="flex gap-2"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-around",
                gap: "1rem",
                marginLeft: "1rem",
              }}
            >
              {filterBubbles.map((bubble) => (
                <Button
                  key={bubble.id}
                  onClick={() => {
                    setSelectedFilter(bubble.id);
                    setSearchQuery("");
                    setFilteredAppointments(appointments);
                  }}
                  style={{
                    cursor: "pointer",
                    backgroundColor:
                      selectedFilter === bubble.id
                        ? "rgb(0 49 61)"
                        : "rgb(77, 154, 166",
                    color: selectedFilter === bubble.id ? "#fff" : "#fff",
                  }}
                >
                  {bubble.label}
                </Button>
              ))}
            </div>
          </div>
          <div>
            <CachedIcon
              onClick={() => fetchAppointments()}
              style={{ cursor: "pointer" }}
            />
          </div>
        </div>

        {selectedFilter === "date" && (
          <div className="row align-items-center px-3 mb-4">
            <Col
              xs={12}
              lg={8}
              className="d-flex mt-3 mt-lg-0 align-items-center position-relative"
            >
              <DateRange
                startDate={startDate}
                setStartDate={setStartDate}
                endDate={endDate}
                setEndDate={setEndDate}
                label="Date range:"
              />
            </Col>
          </div>
        )}

        {(selectedFilter === "name" ||
          selectedFilter === "phone" ||
          selectedFilter === "case_id" ||
          selectedFilter === "token") && (
          <div>
            <div className="searchbar">
              <input
                type="text"
                placeholder={getSearchPlaceholder()}
                onChange={handleSearchInput}
                value={searchQuery}
                maxLength={selectedFilter === "phone" ? 10 : 50}
                onKeyPress={(e) => {
                  if (e.key === "Enter" && searchQuery.trim()) {
                    handleSearchIconClick();
                  }
                }}
              />
              <div
                onClick={handleSearchIconClick}
                style={{
                  cursor: searchQuery.trim() ? "pointer" : "not-allowed",
                  opacity: searchQuery.trim() ? 1 : 0.6,
                }}
              >
                <SearchIcon />
              </div>
            </div>
            <div
              style={{
                color: "red",
                fontSize: "12px",
                marginTop: "4px",
                minHeight: "16px",
              }}
            >
              {getErrorMessage()}
            </div>
          </div>
        )}
        <Box
          sx={{
            height: 430,
            width: "100%",
            "& .super-app-theme--header": {
              backgroundColor: "#fafafa",
              fontSize: "0.9rem",
              fontWeight: "bold",
              textAlign: "left",
            },
          }}
        >
          <DataGrid
            rows={filteredAppointments}
            columns={columns}
            paginationModel={paginationModel}
            onPaginationModelChange={handlePaginationChange}
            pageSizeOptions={[20, 50, 100]}
            getRowId={(row) => row.appointment_id}
            onRowClick={handleRowClick}
            rowSelection={false}
            rowCount={
              hasMore
                ? (paginationModel.page + 2) * paginationModel.pageSize
                : filteredAppointments.length
            }
            paginationMode="server"
          />
        </Box>
        {showAppointmentModal && (
          <AppointmentModal
            show={showAppointmentModal}
            onHide={() => setShowAppointmentModal(false)}
            appointmentId={selectedAppointmentId}
          />
        )}
      </div>
    </>
  );
};

export default Appointmentdate;