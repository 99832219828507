const RX_ONE_ENDPOINTS = {
  USER: {
    SIGN_UP: "signup/user",
    LOGIN: "login/user",
    FORGOT_PASSWORD: "user/forgot/password",
    CHANGE_PASSWORD: "user/change/password",
    RESEND_CONFIRMATION_EMAIL: "user/resend/confirmation",
    KEYS: "user/keys",
    REFERRAL_LINK: "user/referral/link",
    SEND_REFERRAL_EMAILS: "mail/referral/link",
    KYC_STATUS: "user/kyc/status",
    KYC_FORM_CONTACT_INFO: "user/kyc/contact/details",
    KYC_FORM_BUSINESS_DETAILS: "user/kyc/business/details",
    KYC_FORM_ACCOUNT_DETAILS: "user/kyc/account/details",
    KYC_DOCS_VERIFICATION_BUSINESS_PAN: "user/kyc/documents/business/pan",
    KYC_DOCS_VERIFICATION_INCORP_CERTI: "user/kyc/documents/incorp/cert",
    KYC_DOCS_VERIFICATION_SIGNATORY_ID: "user/kyc/documents/auth/signatory",
    KYC_SUBMIT: "user/kyc/documents/submit",
  },
  REWARDS: {
    REDEEM: {
      GET_REWARDS_LIST: "rxone/user/list/rewards",
      GET_POINTS_BALANCE: "rxone/user/points/balance",
      GET_REWARD_IMAGE: "rxone/user/get/reward/image",
      GET_REWARD_DETAILS: "rxone/user/get/reward/details",
      POST_AVAIL_REWARD: "rxone/user/redeem/reward/points",
    },
    POINTS_HISTORY: {
      GET_POINTS_HISTORY: "rxone/user/points/history",
    },
  },
  SETTLEMENTS: {
    LIST_SETTLEMENTS: "rxone/user/payment/settlement/status",
  },
  SUBSCRIPTIONS: {
    GET_A_QUOTE: "rxone/premium/quote/request",
    DO_SUBSCRIPTION: "/rxone/user/list/opd/plans",
    PLAN_PRICE: "/rxone/user/fetch/opd/plan/details",
    VERIFY_PAY: "/rxone/raise/service/plan/order",
    AFTERPAYEMENT_MSG: "/rxone/verify/subscription/payment",
  },
  NOTIFICATION: {
    GET_NOTIFICATION: "rxone/user/fetch/notifications",
    DELETE_NOTIFICATION: "rxone/user/close/notification",
  },
  HOME: {
    GET_CARD: "rxone/user/fetch/dashboard/month/stats",
    GET_DATE: "/rxone/user/fetch/dashboard/stats/years",
    GET_CARD1: "rxone/user/fetch/dashboard/stats",
  },
  SETTINGS: {
    LIST_FACILITY_STAFF: "list/facility/users",
    SWITCH_STAFF_ACCESS: "facility/user/access/switch",
    REGISTER_USER: "register/facility/user",
    USER_DETAILS: "facility/user/operations",
    MEDIA_NOTIFICATION: "facility/user/notification/settings",
    FETCH_ROLES: "rxone/user/fetch/facility/distinct/dashboard/roles",
    FETCH_ROLE_DETAILS: "rxone/user/fetch/facility/dashboard/privileges",
    SUBMIT_ROLE: "rxone/user/create/dashboard/role",
    ROLE_SUBMITION: "rxone/user/update/dashboard/role",
  },
  NOTIFICATION: {
    NOTIFICATION: 'rxone/user/reg/push/note/device',
  }
};


const RX_OPD_ENDPOINTS = {
  HOSPITAL: {
    GET_HOSPITAL_REPORT_COLLECTION: "hospital/reporting/collection",
    GET_HOSPITAL_REPORT_APPOINTMENTS: "hospital/reporting/appointments/count",
    FETCH_A_HOSPITAL: "hospital/registration/record",
    ALL_HOSPITALS: "hospital",
    LIST_HOSPITAL_CALENDAR: "hospital/holiday",
    CREATE_PATIENT_PORTAL_QR: "hospital/portal/qr/patient",
    GET_PATIENT_PORTAL_QR: "hospital/patient/portal/qr",
    GET_DOCTOR_PORTAL_QR: "hospital/doctor/portal/qr",
    CREATE_DOCTOR_PORTAL_QR: "hospital/portal/qr/doctor",
    CHECK_FACILITY_SHORT_NAME: "hospital/check/shortname",
    REGISTER_HOSPITAL: "hospital",
    UPLOAD_HOSPITAL_DOCS: "hospital/reg/documents",
    GET_HOSPITAL_LOGO: "hospital/logo",
    CHANGE_HOSPITAL_LOGO: "hospital/change/logo",
    GET_HOSPITAL: "hospital/profile/record",
    UPDATE_HOSPITAL: "hospital/profile/record",
    DELETE_HOSPITAL: "hospital/delete/record",
    APPOINTMENTS: "hospital/appointments",
    RMP_SEARCH: "hospital/find/nmc/rmp",
    FETCH_A_DOCTOR: "hospital/doctor/registration/details",
    UPDATE_DOCTOR: "hospital/doctor/update/details",
    GET_DOCTOR_AVATAR: "doctor/avatar",
    ALL_DOCTORS: "hospital/doctors",
    REGISTER_DOCTOR: "hospital/doctors",
    DOCTORS_PIC: "hospital/doctor/pic",
    UPLOAD_DOCTOR_DETAILS: "hospital/doctor/registration/details",
    UPDATE_DOCTOR_ONLINE_BOOKING: "/hospital/doctor/allow/online/booking/switch",
    UPDATE_DOCTOR_REASSIGN_APPOINTMENT: "/hospital/doctor/allow/appointment/reassignment/switch",
    ADD_TITTLE: "hospital/doctor/show/in/prescription/switch",
    // REGISTER_DOCTOR: "hospital/portal/qr/doctor",
    CREATE_DOCTOR_RECURRING_SCHEDULE: "v2/hospital/doctor/recurring/schedule",
    FETCH_DOCTOR_RECURRING_SCHEDULE:
      "v2/hospital/doctor/fetch/recurring/schedule",
    CREATE_DOCTOR_DAY_SCHEDULE: "v2/hospital/doctor/schedule/ops",
    FETCH_DOCTOR_DAY_SCHEDULE: "v2/hospital/doctor/schedule/ops",
    UPLOAD_DOCTOR_DOCS: "hospital/doctor/upload/documents",
    SWITCH_DOCTOR_AVAILABILITY: "hospital/doctor/available/switch",
    SWITCH_MARKET_PLACE: "hospital/doctor/marketplace/switch",
    UPDATE_DOCTOR_CONSULT_CHARGES: "hospital/doctor/charges/update",
    UPDATE_DOCTOR_ONLINE_DISCOUNT: "hospital/doctor/online/discount/update",
    UPDATE_DOCTOR_SCHEDULE: "/hospital/doctor/schedule/ops",
    DELETE_DOCTOR: "hospital/doctor/remove/profile",
    SYSTEM_OF_MEDICNE: "v2/facility/som/lookup",
    CHECKING_NAME: "v2/facility/check/shortname",
    REGISTRATION_STEP_1: "v2/hospital/register/step1",
    FECTH_HOS_REGISTRATION: "v2/hospital/fetch/step1",
    REGISTRATION_STEP_2: "v2/hospital/register/step2",
    FETCH_REGISTRATION_STEP2: "v2/hospital/register/step2",
    REGISTRATION_STEP_3: "v2/hospital/register/step3",
    FETCH_REGISTRATION_STEP3:"v2/hospital/register/step3",

    UPLOAD_REGISTRATION_CERTIFICATE_STEP4:"v2/hospital/register/step4/upload/reg/cert",
    VIEW_REGISTRATION_CERTIFICATE_STEP4:"v2/hospital/register/step4/view/reg/cert",
    UPLOAD_BRAND_LOGO_STEP4: "v2/hospital/register/step4/upload/logo",
    VIEW_BRAND_LOGO_STEP4:"v2/hospital/register/step4/view/logo",
    LIST_FACILITY_IMAGES_STEP4:"v2/hospital/register/step4/list/facility/pics",
    UPLOAD_FACILITY_PIC_STEP4:"v2/hospital/register/step4/upload/facility/pics",
    REMOVE_FACILITY_PIC_STEP4:"v2/hospital/register/step4/remove/facility/pics",
    DOCU_SUBMISSION_STEP4:"v2/hospital/register/step4/submission",
    OPD: {
      LIST_PATIENTS: "facility/list/patients",
      PATIENT_INFO: "facility/fetch/patient/profile",
      CREATE_PATIENT_PROFILE: "facility/create/patient/profile",
      ADD_TO_EXSISTING_PROFILE: "facility/patient/family/profile",
      LIST_AVAILABLE_DOCTORS: "facility/list/doctors",
      LIST_AVAILABLE_SLOTS: "facility/doctor/slots",
      INITIATE_APPOINTMENT_BOOKING: "facility/initiate/appointment",
      INITIATE_APPOINTMENT_ORDER: "facility/initiate/order",
      VERIFY_ONLINE_PAYMENT: "facility/verify/payment",
      CONFIRM_BOOK_APPOINTMENT_CASH_PAYMENT: "facility/confirm/cash/payment",
      LIST_UNPAID_CASH_ORDERS: "hospital/list/unpaid/orders", 
      CONFIRM_CASH_PAYMENT: "order/cash/payment",
      LIST_INVOICES: "hospital/appointment/invoices",
      GENERATE_INVOICE: "hospital/generate/opd/invoices",
      ORDERS_LIST: "hospital/appointment/orders",
      ORDERS_DETAILS:"hospital/fetch/order/details",
      NEWINVOICE_LIST:"hospital/list/paid/orders/invoices",
      SHOW_INVOICE:"/hospital/generate/paid/order/invoice",
      ORDER_PAY_LINK:"hospital/create/pay/link",
      CREATE_SERVICE_ORDER:"hospital/services/manual/order",
      DELETE_SERVICE_ORDER: "hospital/cancel/service/order",
      CONFIRM_CASH_PAYMENT_MODE:"hospital/services/order/cash/payment",
      CUSTOMER_DETAILS:"hospital/list/opd/customers",
      FETCH_SERVICES: "hospital/list/recent/opd/services",
      NEW_CASES: "facility/list/patient/cases",
      PATIENT_OTP: "facility/generate/verify/code",
      OTP_VERIFY: "facility/auth/verify/code",
      FETCH_ORDER: "hospital/fetch/order/service/transactions",
      PAYMENT_ORDER: "hospital/capture/order/service/transactions",
    },
    WEB_APP: {
      LIST_WEB_APPS: "facilities/list/all/for/web/app",
      BRAND_LOGO: "facilities/get/brand/logo/custom/web/page",
      CURRENT_VALUE: "facilities/fetch/custom/pt/web/page",
      PREVIEW_VALUE: "facilities/preview/custom/web/page",
      LIST_DOCTORS: "facilities/list/confirmed/doctors",
      LIST_PHARMACIES: "facilities/list/approved/pharmacies",
      LIST_PATH_LABS: "facilities/list/approved/path/labs",
      PUBLISH_FACILITY_DATA: "facilities/publish/custom/pt/web/page",
      CHANGE_FACILITY_LOGO: "facilities/change/brand/logo/custom/web/page",
      LIST_FACILITY_IMAGES: "facilities/list/images/urls/web/page",
      ADD_FACILITY_IMAGES: "facilities/add/image/custom/web/page",
      DELETE_FACILITY_IMAGES: "facilities/remove/image/custom/web/page",
      QR_CODE: "facilities/get/web/page/qr"
    },
    FACILITIES: {
      LIST_FACILITIES: "facilities/list/all",
      ACCEPT_PAYEMENT_SWITCH: "hospital/switch/accept/online/payment"
    },
    APPOINTMENT: {
      LIST_ALL_DOCTORS: "hospital/list/all/doctors",
      LIST_DOCS_APPOINTMENTS: "hospital/doc/list/appointments",
      LIST_APPOINTMENT_PAGE: "hospital/doc/list/appointments/pagination",
      FETCH_APPOINTMENT_DETAILS: "hospital/appointment/details",
      FETCH_APPOINTMENT_LINEAGE: "hospital/appointment/lineage",
      PATIENT_PROFILE: "hospital/patient/profile/pic",
      CANCEL_APPOINTMENT: "appointment/cancel",
      ADD_FILE: "/v2/hospital/upload/patient/file/",
      NEW_FILES: "/v2/hospital/list/patient/file/",
      DELETE_PATIENT_FILE: "/v2/hospital/delete/patient/file/",
      SHARE_FILE: "/v2/hospital/share/patient/files/",
      VIEWNGEN: "v2/hospital/generate/patient/case/file",
      VIEWPRE: "v2/hospital/patient/file/download",
    },
    VITALS: {
     FETCH_BIOMARKERS: "v2/hospital/list/bio/trackers",
      FETCH_BIOMARKERS_DATA: "v2/hospital/clinical/finding/ops",
      GENERATE_VITALS: "v2/hospital/generate/clinical/finding",
      VIEW_VITALS: "v2/hospital/download/clinical/finding",
    },
    PATIENT: {
      PATIENT_LIST: "facility/list/registered/users",
      PATIENT_LIST_FILTER: "facility/list/registered/users/pagination",
      PATIENT_FILTER_LIST: "facility/pt/data/filters",
      PATIENT_APPOINTMENT_LIST: "facility/list/patient/appointments",
      MEDICAL_RECORDS: "facility/list/patient/records",
      DOWNLOAD_MEDICAL_RECORDS: "facility/patient/record/download",
      PATIENT_APPOINTMENT_NOTES: "facility/patient/appointment/notes",
      DOCTOR_PRESCRIPTION: "facility/fetch/appointment/prescription",
      DOWNLOAD_REGISTERED_PATIENTS: "facility/download/registered/users",
      UPLOAD_PATIENTS: "facility/patient/bulk/upload",
      NOTIFICATION: "hospital/list/patient/custom/notifications",
      ADD_NOTIFICATION: "hospital/send/patient/custom/notification",
      NOTIFICATION_DETAILS: "hospital/send/patient/custom/notification",
      PATIENT_APPOINTMENT: "hospital/list/patient/cases",
      PATIENT_APPOINTMENT_CASE: "hospital/fetch/patient/cases/all/appoitment",
      PATIENT_CASE_FILE: "v2/hospital/list/patient/file",
    },
  },
  PHARMACY: {
    PHARMACY_LIST: "pharma/master",
  },
  BOOKLET: {
    BOOKLET_PHOTO:"v2/hospital/upload/booklet/pages",
    REMOVE_IMAGE: "v2/hospital/delete/booklet/pages",
    FETCH_IMG: "v2/hospital/list/booklet/pages",
    BOOKLET_VIEW: "v2/hospital/generate/booklet/pdf",
    BOOKLET_GET: "v2/hospital/view/download/booklet",
    
  }

};

export { RX_ONE_ENDPOINTS, RX_OPD_ENDPOINTS };
